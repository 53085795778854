// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { CodeBlock } from "../../../components/widgets/codeBlock"
import { clienteScripts } from "../../../scripts/clientes"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiReferenciaLanding: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS withSide">
            <div className="content">
                <h2>Llave del CSD de clientes</h2>
                <h3>
                    Subir la llave
                </h3>
                <p>
                    Subir las llaves, al igual que los certificados, se realiza en Front-End, subiendo los archivos como Form Data (multipart form-data).
                    A la derecha verás una función declarada en JavaScript para browser, la cual no depende de ningun framework y puede ser llamada tal cual. <br />
                </p>
                <p>
                    Para ejecutar esta función usando un input normal de HTML puedes hacer lo siguiente, usando un input para el archivo y otro para la contraseña.
                </p>
                <pre>
                    <code>
                        <b>POST:</b> https://api.fiscalpop.com/api/v1/upload/key/<b><span className="error">:authToken</span></b>
                    </code>
                </pre>
                <h3>Respuesta del request</h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>keyPem: </b> Key del CSD en formato PEM
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>keyModulus: </b> El Modulus de la llave del CSD<br />
                            <span className="quote small">El Modulus es una valor de tipo MD5 de OPENSSL que encuentras en los certificados CSR (tal como los CSD).</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>cer: </b> El Cer en formato PEM
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>cerNumber: </b> El número de serie del Certificado, como lo solicita el SAT.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>cerModulus: </b> <br />
                            <span className="quote small">El Modulus es una valor de tipo MD5 de OPENSSL que encuentras en los certificados CSR (tal como los CSD).</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>notBefore: </b> El CSD puede usarse a partir de esta fecha, no antes.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>notAfter: </b> Fecha de caducidad del CSD, después de esta fecha, no puede usarse.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>authToken: </b> La llave de acceso del cliente, confirmando el token usado
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>masterToken: </b> Esta es <span className="error">la llave de acceso principal</span>, confirmando la clave usada para crear el cliente.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>modulusMatch: </b> Valor true / false que indica si la llave pertenece al certificado <br />
                            <span className="small quote">Si el modulusMatch es true, y las fechas notBefore / notAfter son válidas, el cliente esta listo para facturar</span>
                        </p>
.                    </li>
                </ul>
                <p>
                Los archivos de tu emisor (Cer y Key) no se guardan, una vez procesados se descartan permanentemente, de esta manera no tienes porque preocuparte de almacenamiento seguro
                </p>
                <SectionNavigate next={`/api/referencia/clientes-editar`} previous={`/api/referencia/clientes-certificados`}/>
            </div>
            <div className="side">
            <CodeBlock title="Subir Llave CSD" requests={clienteScripts.llaves.requests} response={clienteScripts.llaves.response} />
            </div>
        </div>
    </Layout>
)

export default ApiReferenciaLanding
